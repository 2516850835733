import $ from 'jquery';

function calcOffset(offset, $target) {
  let o = offset;

  if (o === 'auto') {
    const margin = parseInt($target.css('margin-top'), 10);
    const padding = parseInt($target.css('padding-top'), 10);
    o = padding === 0 && margin > 0 ? margin : 0;
  } else {
    o = parseInt(o, 10);
    o = o > 0 ? o : 0;
  }
  return o;
}

function scrollTo() {
  const $links = $('.js-scrollTo');
  const $htmlBody = $('html,body');

  $links.on('click', e => {
    const $el = $(e.currentTarget);
    const $target = $($el.data('target'));

    if (!$target.length) return;
    e.preventDefault();

    const offset = calcOffset($el.data('offset'), $target);

    $htmlBody.animate({ scrollTop: $target.offset().top - offset }, 300, () => {
      $el.blur();
    });
  });
}

export default scrollTo;
