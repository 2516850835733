export default `
  <div class="mfp-figure">
    <div class="mfp-header">
      <div class="mfp-close"></div>
    </div>
    <div class="mfp-iframe-wrap">
      <div class="mfp-iframe-scaler">
        <iframe class="mfp-iframe" src="//about:blank" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
    <div class="mfp-title mfp-footer"></div>
  </div>
`;
