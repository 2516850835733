import $ from 'jquery';

class Nav {
  open = 'is-open';

  constructor($el) {
    this.$nav = $el.find('.js-nav_nav');
    this.$open = $el.find('.js-nav_open');
    this.$close = $el.find('.js-nav_close');

    this.$togglers = $el.find('.js-nav_toggler');
    this.$lists = $el.find('.js-nav_list');

    this.$originalToggler = this.$togglers.find(this.open);
    this.$originalList = this.$lists.find(this.open);

    this.$body = $('body');

    // Events
    this.$open.on('click', this.openNav);
    this.$close.on('click', this.closeNav);
    this.$togglers.on('click', this.toggleSub);
    $(window).on('keydown', e => {
      if (e.keyCode === 27) {
        this.closeNav();
      }
    });
  }

  openNav = () => {
    this.$nav.addClass(this.open);
    this.$body.css({ overflow: 'hidden' });
  };

  closeNav = () => {
    this.$nav.removeClass(this.open);
    this.$body.removeAttr('style');

    setTimeout(() => {
      this.$togglers.removeClass(this.open);
      this.$lists.removeClass(this.open);
      this.$originalToggler.addClass(this.open);
      this.$originalList.addClass(this.open);
    }, 200);
  };

  toggleSub = e => {
    const $el = $(e.currentTarget);
    const $list = $($el.data('target'));

    if ($el.hasClass(this.open)) {
      $el.removeClass(this.open);
      $list.removeClass(this.open);
    } else {
      this.$togglers.removeClass(this.open);
      this.$lists.removeClass(this.open);
      $el.addClass(this.open);
      $list.addClass(this.open);
    }
  };
}

export default Nav;
