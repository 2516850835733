import $ from 'jquery';
import 'magnific-popup';

// Templates
import iframeMarkup from './../templates/mfp-iframe';
import tLoading from './../templates/mfp-loading';
import closeMarkup from './../templates/mfp-close';

const mfpCallbacks = {
  open() {
    $.magnificPopup.instance.next = () => {
      this.wrap.removeClass('mfp-image-loaded');

      setTimeout(() => {
        $.magnificPopup.proto.next.call(this);
      }, 200);
    };

    $.magnificPopup.instance.prev = () => {
      this.wrap.removeClass('mfp-image-loaded');

      setTimeout(() => {
        $.magnificPopup.proto.prev.call(this);
      }, 200);
    };
  },

  updateStatus(data) {
    if (data.status === 'ready') {
      setTimeout(() => {
        this.wrap.addClass('mfp-image-loaded');
      }, 16);
    }
  },

  imageLoadComplete() {
    setTimeout(() => {
      this.wrap.addClass('mfp-image-loaded');
    }, 16);
  },

  close() {
    this.wrap.removeClass('mfp-image-loaded');
  },

  buildControls() {
    this.content.append(this.arrowLeft);
    this.content.append(this.arrowRight);
  },

  markupParse(template, values, item) {
    values.title = item.el.data('title');
  },
};

function initVideos() {
  const $videos = $('.js-videoPopup');

  if ($videos.length) {
    $videos.magnificPopup({
      type: 'iframe',
      iframe: {
        markup: iframeMarkup,
      },
      removalDelay: 300,
      mainClass: 'mfp-fade',
      autoFocusLast: false,
      callbacks: mfpCallbacks,
      tLoading,
      closeMarkup,
    });
  }
}

export default initVideos;
