import $ from 'jquery';

class Toggle {
  init() {
    this.$togglers = $('.js-toggle-toggler');
    this.$sections = $('.js-toggle-section');
    this.$close = $('.js-toggle-close');

    this.css = {
      open: 'is-open',
    };

    this.$togglers.on('click', this.handleClick);
    $(window).on('click', this.closeAll);
    this.$close.on('click', this.closeAll);

    $(window).on('keydown', e => {
      if (e.keyCode === 27) {
        this.closeAll();
      }
    });

    this.$togglers.add(this.$sections).on('click', e => {
      e.stopPropagation();
    });
  }

  handleClick = e => {
    e.preventDefault();

    const $el = $(e.currentTarget);
    const $target = $($el.data('target'));

    if ($el.hasClass(this.css.open)) {
      this.closeAll();
    } else {
      this.closeAll();
      $el.addClass(this.css.open);
      $target.addClass(this.css.open);

      const $focus = $($target.data('focus'));

      if ($focus.length) {
        $target.on('transitionend', () => {
          $focus.focus();
          $target.off('transitionend');
        });
      }
    }
  };

  closeAll = () => {
    this.$togglers.removeClass(this.css.open);
    this.$sections.removeClass(this.css.open);
    $(document).trigger('toggler:close');
  };
}

export default new Toggle();
