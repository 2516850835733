import 'slick-carousel';

class Slider {
  constructor($el) {
    this.$prev = $el.find('.js-slider_prev');
    this.$next = $el.find('.js-slider_next');

    const type = $el.data('type');

    const $slider = $el.find('.js-slider_slider');

    $slider.slick(this.getOptions(type));
    $el.addClass('is-initialized');
  }

  getOptions(type) {
    let options = {};

    switch (type) {
      case 'hero':
        options = this.getHeroOptions();
        break;
      case 'testimonials':
        options = this.getTesimonialsOptions();
        break;
    }

    return options;
  }

  getHeroOptions() {
    return {
      prevArrow: this.$prev,
      nextArrow: this.$next,
      fade: true,
      responsive: [
        {
          breakpoint: 728,
          settings: {
            dots: true,
          },
        },
      ],
    };
  }

  getTesimonialsOptions() {
    return {
      arrows: false,
      dots: true,
      fade: true,
    };
  }
}

export default Slider;
