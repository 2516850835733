import $ from 'jquery';
import svg4everybody from 'svg4everybody';
import picturefill from 'picturefill';

import toggle from 'modules/toggle';
import scrollTo from 'modules/scrollTo';
import lightbox from 'modules/lightbox';

import factory from 'utils/factory';

import Nav from 'modules/Nav';
import Slider from 'modules/Slider';

$(document).ready(() => {
  svg4everybody();
  picturefill({ elements: $('.js-picturefill').toArray() });
  scrollTo();
  toggle.init();
  lightbox();
  factory(Nav, '.js-nav');
  factory(Slider, '.js-slider');
});
